<template>
  <button
    class="account__theme-badge"
    :class="{ active: state.isActive }"
    @click="
      !state.isActive && state.isLimited
        ? actions.showWarning()
        : actions.selectTheme()
    "
  >
    {{ themeName }}
  </button>
</template>

<script>
import { reactive, onMounted, onBeforeUpdate, computed } from "vue";

export default {
  name: "AccountThemeButton",
  components: {},
  props: {
    theme: {
      type: Object,
      required: true,
    },
    themeName: {
      type: String,
      required: true,
    },
    unselectedTheme: {
      type: String,
      required: false,
    },
    selectedThemes: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isLimited: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["selectTheme", "unselectTheme", "showWarning"],
  setup(props, { emit }) {
    const state = reactive({
      isActive: false,
      isLimited: computed(() => {
        return props.isLimited;
      }),
    });

    onMounted(() => {
      state.isActive = props.selectedThemes.find(
        (element) => element.id === props.theme.id
      );
    });

    onBeforeUpdate(() => {
      if (props.unselectedTheme === props.theme) {
        state.isActive = false;
      }
    });

    const actions = {
      selectTheme: () => {
        state.isActive = !state.isActive;
        if (state.isActive) {
          emit("selectTheme", props.theme);
        } else if (!state.isActive) {
          emit("unselectTheme", props.theme);
        }
      },
      showWarning: () => {
        emit("showWarning");
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
