<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>

    <!-- 등록 이메일 -->
    <div v-if="!state.pageLoading" class="account-container">
      <page-header title="프로필"> </page-header>

      <div class="account-body">
        <card-basic class="email-card">
          <h4>등록 이메일</h4>
          <p>{{ state.user.email }}</p>
        </card-basic>

        <!-- 계정 설정 -->
        <card-basic class="account-card">
          <h4>계정 설정</h4>

          <div class="name-section">
            <input-basic
              class="name-input"
              label="크리에이터명"
              :default-value="state.displayName"
              @updateData="(value) => actions.updateDisplayName(value)"
            ></input-basic>

            <button-basic
              class="save-btn"
              text="변경하기"
              bg-color="#ECF1F4"
              color="#0D0D10"
              :disabled="!state.updatableDisplayName"
              @action="actions.putUserDisplayName(state.displayName)"
            ></button-basic>
          </div>

          <div class="phone-section">
            <p class="sub-text-s2">휴대폰 번호</p>
            <div class="content">
              <span class="b-text-1 text-gray-second">{{ state.phone }}</span>
              <button-basic
                class="save-btn"
                text="변경하기"
                bg-color="#ECF1F4"
                color="#0D0D10"
                @action="actions.openEditPhoneModal()"
              ></button-basic>
            </div>
          </div>

          <div class="pw-section">
            <p class="sub-text-s2">비밀번호</p>
            <div class="content">
              <span>
                <span class="password-dot"></span>
                <span class="password-dot"></span>
                <span class="password-dot"></span>
                <span class="password-dot"></span>
                <span class="password-dot"></span>
                <span class="password-dot"></span>
              </span>
              <button-basic
                class="save-btn"
                text="변경하기"
                bg-color="#ECF1F4"
                color="#0D0D10"
                @action="actions.openEditPasswordModal()"
              ></button-basic>
            </div>
          </div>
        </card-basic>

        <!--      활동 주제 -->
        <card-basic class="tag-card">
          <div class="header">
            <h4>활동 주제</h4>
            <span
              v-if="state.isWarning && state.isLimited"
              class="sub-text-s3 text-red-50"
            >
              5개 이상 선택할 수 없습니다.
            </span>
          </div>

          <p class="sub-text-s2 text-gray-second">
            활동 주제는 최대 5개까지 선택 가능합니다.
          </p>

          <div class="tag-list">
            <account-theme-button
              v-for="(interest, index) in state.interestThemes"
              :key="`interest-${index}`"
              :theme="interest"
              :theme-name="interest.name"
              :is-limited="state.isLimited"
              :selected-themes="state.selectedThemes"
              :unselected-theme="state.unselectedTheme"
              @showWarning="actions.showWarning()"
              @selectTheme="
                (theme) => {
                  actions.selectTheme(theme);
                }
              "
              @unselectTheme="
                (theme) => {
                  actions.unselectTheme(theme);
                }
              "
            ></account-theme-button>
          </div>
        </card-basic>

        <!--      이벤트 혜택 알림-->
        <card-basic class="notification-card">
          <h4>이벤트 및 혜택 알림</h4>
          <p class="sub-text-s2 text-gray-second">
            서비스의 중요 안내사항 및 정보는 아래 수신 여부와 관계없이
            발송됩니다.
          </p>

          <div class="toggle-box">
            <label class="sub-text-s2"> 이메일 </label>
            <input-switch
              v-model="state.isEmail"
              :is-toggle-event="true"
              @updateData="
                (data) => {
                  actions.updateEmailNotification(data);
                }
              "
            ></input-switch>
          </div>
          <div class="toggle-box">
            <label class="sub-text-s2"> 문자 메시지 </label>
            <input-switch
              v-model="state.isSms"
              :is-toggle-event="true"
              @updateData="
                (data) => {
                  actions.updateIsSmsNotification(data);
                }
              "
            ></input-switch>
          </div>
        </card-basic>
      </div>
    </div>

    <edit-phone-modal
      v-if="state.showEditPhoneModal"
      :is-header-section="false"
      :is-footer-section="false"
      @hideModal="actions.closeEditPhoneModal()"
      @updatePhoneNumber="
        (val) => {
          actions.updatePhoneNumber(val);
        }
      "
    ></edit-phone-modal>
    <edit-password-modal
      v-if="state.showEditPasswordModal"
      :is-header-section="false"
      :is-footer-section="false"
      @hideModal="actions.closeEditPasswordModal()"
    ></edit-password-modal>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { reactive, computed, onMounted, onBeforeMount } from "vue";
import PageHeader from "../../../components/console/headers/PageHeader";
import EditPasswordModal from "./editPasswordModal/EditPasswordModal";
import EditPhoneModal from "./editPhoneModal/EditPhoneModal";
import AccountThemeButton from "./AccountThemeButton/AccountThemeButton";
import swal from "../../../helper/swal";
import ApiService from "@/api";
import InputSwitch from "../../../components/console/inputs/InputSwitch";
import PageLoading from "../../../components/console/loadings/PageLoading";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import InputBasic from "@/components/console/inputs/InputBasic.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "Account",
  components: {
    ButtonBasic,
    InputBasic,
    CardBasic,
    PageLoading,
    InputSwitch,
    EditPasswordModal,
    EditPhoneModal,
    PageHeader,
    AccountThemeButton,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      pageLoading: false,
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      displayName: "",
      updatableDisplayName: computed(() => {
        return state.user.userProfile.displayName !== state.displayName;
      }),
      description: "",
      avatar: "",
      phone: "",
      updatablePhone: "",
      passWord: "",
      showEditPhoneModal: false,
      showEditPasswordModal: false,
      isInterestError: false,
      interests: [],
      interestThemes: [],
      selectedThemes: [],
      unselectedTheme: "",
      isLimited: computed(() => {
        return state.selectedThemes.length === 5;
      }),
      isWarning: false,
      isEmail: store.getters["auth/user"].isEmail,
      isSms: store.getters["auth/user"].isSms,
    });

    onBeforeMount(async () => {
      state.pageLoading = true;
      await ApiService.getUserInterest().then((res) => {
        if (res.data.success) {
          state.interestThemes = res.data.meta.interestTags;
          state.selectedThemes = res.data.data;
          state.pageLoading = false;
        }
      });
    });

    onMounted(() => {
      state.displayName = state.user.userProfile.displayName;
      state.phone = state.user.phone;
      state.description = state.user.userProfile.description
        ? state.user.userProfile.description
        : "";
      state.avatar = state.user.avatar;
    });

    const putUserInterest = (data) => {
      let interestTags = data.map((item) => item.name);
      let payload = { tags: interestTags };
      ApiService.putUserInterest(payload);
    };

    const actions = {
      updateDisplayName: (value) => {
        state.displayName = value.trim();
      },
      putUserDisplayName: (displayName) => {
        let payload = {
          displayName: displayName,
          avatar: state.avatar,
          description: state.description,
        };

        store.dispatch("auth/putUserProfile", payload).then(() => {
          swal.successToast("변경되었습니다.");
        });
      },
      openEditPasswordModal: () => {
        state.showEditPasswordModal = true;
      },
      openEditPhoneModal: () => {
        state.showEditPhoneModal = true;
      },
      closeEditPasswordModal: () => {
        state.showEditPasswordModal = false;
      },
      closeEditPhoneModal: () => {
        state.showEditPhoneModal = false;
      },
      selectTheme: (theme) => {
        if (state.selectedThemes.length === 5) {
          state.unselectedTheme = state.selectedThemes.pop();
          putUserInterest(state.selectedThemes);
          return;
        }
        state.selectedThemes.push(theme);
        putUserInterest(state.selectedThemes);
      },
      unselectTheme: (theme) => {
        if (state.isWarning) {
          state.isWarning = false;
        }
        let index = state.selectedThemes.findIndex(
          (selectedtheme) => selectedtheme.id === theme.id
        );
        if (index > -1) {
          state.selectedThemes.splice(index, 1);
        }
        putUserInterest(state.selectedThemes);
      },
      showWarning: () => {
        state.isWarning = true;
      },
      updatePhoneNumber: (data) => {
        state.phone = data;
      },
      updateEmailNotification: (data) => {
        let payload = {
          isEmail: data,
        };
        ApiService.putUserIsEmail(payload).then((res) => {
          if (res.data.success) {
            swal.editCompleteToast();
          } else {
            swal.errorToast("다시 시도해주세요.");
          }
        });
      },
      updateIsSmsNotification: (data) => {
        let payload = {
          isSms: data,
        };
        ApiService.putUserIsSms(payload).then((res) => {
          if (res.data.success) {
            swal.editCompleteToast();
          } else {
            swal.errorToast("다시 시도해주세요.");
          }
        });
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
