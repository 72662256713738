<template>
  <base-modal title="휴대폰 번호 변경" @closeModal="$emit('hideModal')">
    <template #modalBody>
      <div class="select-box">
        <select
          :value="state.code"
          class="country-select"
          @change="actions.updateCountryCode($event)"
        >
          <option
            v-for="(item, index) in countryCodes"
            :key="`country-${index}`"
            :value="item.dial_code"
          >
            {{ `${item.dial_code} ${item.name}` }}
          </option>
        </select>

        <arrow-icon class="arrow-icon" direction="down"></arrow-icon>
      </div>

      <div class="auth-phone">
        <input-basic
          class="phone-input"
          :default-value="state.phone"
          placeholder="숫자만 입력"
          @updateData="(value) => actions.updatePhone(value)"
        ></input-basic>
        <button-basic
          class="send-btn"
          text="인증번호 발송"
          bg-color="#ECF1F4"
          color="#0D0D10"
          :disabled="!state.isPhone"
          @action="actions.VerifyPhoneNumber()"
        ></button-basic>
      </div>

      <div class="auth-confirm">
        <input-basic
          class="code-input"
          label="인증번호 확인"
          placeholder="인증 번호 입력"
          :error-message="state.errorMessage.validation"
          :default-value="state.phoneValidationCode"
          @updateData="(value) => actions.updatePhoneValidationCode(value)"
        ></input-basic>

        <button-basic
          class="auth-btn"
          text="확인"
          bg-color="#ECF1F4"
          color="#0D0D10"
          :disabled="!state.isPhoneValidationCode"
          @action="actions.checkAuthPhoneVerificationCode()"
        ></button-basic>
      </div>

      <div class="btn-wrapper">
        <button-basic
          class="cancel-btn"
          text="취소"
          bg-color="#ECF1F4"
          color="#0D0D10"
          @action="$emit('hideModal')"
        ></button-basic>

        <button-basic
          class="save-btn"
          text="변경"
          :disabled="!state.isSuccess"
          @action="$emit('hideModal')"
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, reactive } from "vue";
import BaseModal from "../../../../components/common/BaseModal.vue";
import ApiService from "@/api";
import swal from "../../../../helper/swal";
import * as countryCode from "../../../../consts/country-code.json";
import ArrowIcon from "../../../../components/console/icons/ArrowIcon";
import InputBasic from "@/components/console/inputs/InputBasic.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "EditPhoneModal",
  components: { ButtonBasic, InputBasic, ArrowIcon, BaseModal },
  props: {},
  emits: ["hideModal", "updatePhoneNumber"],
  setup(props, { emit }) {
    const state = reactive({
      phone: "",
      phoneResoureceId: "",
      phoneValidationCode: "",
      code: "+82",
      isPhone: computed(() => {
        return state.phone.length > 0;
      }),
      isPhoneValidationCode: computed(() => {
        return state.phoneValidationCode;
      }),
      errorMessage: {
        phone: "",
        validation: "",
      },
      isSuccess: false,
    });

    const countryCodes = countryCode.default;

    const actions = {
      updatePhone: (value) => {
        state.phone = value;
      },
      updateCountryCode: (e) => {
        state.code = e.target.value;
      },
      updatePhoneValidationCode: (value) => {
        state.phoneValidationCode = value;
      },
      VerifyPhoneNumber: async () => {
        if (state.code.length === 0) {
          return;
        }
        if (state.phone.length === 0) {
          return;
        }

        let formData = new FormData();
        let phoneNumber = `${state.code} ${state.phone}`;
        formData.append("phone", phoneNumber);

        let payload = formData;
        await ApiService.postAuthPhoneNumber(payload).then((res) => {
          if (state.errorMessage.validation.length > 0) {
            state.errorMessage.validation = "";
          }
          if (res.data.success) {
            state.phoneResoureceId = res.data.data.phoneResourceId;
            swal.successToast("휴대폰으로 인증번호가 발송되었습니다.");
          }
        });
      },
      checkAuthPhoneVerificationCode: async () => {
        let formData = new FormData();

        formData.append("code", state.phoneValidationCode);
        let payload = formData;

        try {
          await ApiService.postAuthPhoneVerificationCode(
            state.phoneResoureceId,
            payload
          ).then((res) => {
            if (res.data.success) {
              swal.successToast("확인되었습니다.");
              emit("updatePhoneNumber", state.phone);
              state.isSuccess = true;
            } else {
              state.errorMessage.validation = res.data.message;
            }
          });
        } catch (e) {
          if (e.response.status === 400) {
            state.errorMessage.validation = e.response.data.message;
          }
        }
      },
    };

    return { state, actions, countryCodes };
  },
};
</script>

<style src="./style.css" scoped></style>
