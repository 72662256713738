<template>
  <base-modal title="비밀번호 변경" @closeModal="$emit('hideModal')">
    <template #modalBody>
      <input-basic
        class="pwd-input"
        label="현재 비밀번호"
        placeholder="현재 비밀번호를 입력해주세요"
        type="password"
        :error-message="state.currentPwdErrMsg"
        :default-value="state.passwordForm.nowPassword"
        @updateData="
          (value) => {
            actions.updateNowPassword(value);
          }
        "
      ></input-basic>

      <input-basic
        class="pwd-input"
        label="새 비밀번호(영문, 숫자, 특수문자 조합 6자리 이상)"
        placeholder="새 비밀번호를 입력해주세요"
        type="password"
        :error-message="state.passwordErrorMessage"
        :default-value="state.passwordForm.password"
        @updateData="
          (value) => {
            actions.updatePassword(value);
          }
        "
      ></input-basic>

      <input-basic
        class="pwd-input"
        label="새 비밀번호 확인"
        placeholder="새 비밀번호를 한번 더 입력해주세요"
        type="password"
        :error-message="state.passwordConfirmationErrorMessage"
        :default-value="state.passwordForm.passwordConfirmation"
        @updateData="
          (value) => {
            actions.updatePasswordConfirmation(value);
          }
        "
      ></input-basic>

      <div class="btn-wrapper">
        <button-basic
          text="취소"
          bg-color="#ECF1F4"
          color="#0D0D10"
          @action="$emit('hideModal')"
        ></button-basic>

        <button-basic
          text="변경"
          :disabled="!state.isSuccess"
          @action="actions.putUserPassword()"
        ></button-basic>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { computed, reactive } from "vue";
import BaseModal from "../../../../components/common/BaseModal.vue";
import InputBasic from "../../../../components/console/inputs/InputBasic";
import ApiService from "@/api";
import helper from "@/helper";
import swal from "../../../../helper/swal";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "EditPasswordModal",
  components: { ButtonBasic, BaseModal, InputBasic },
  props: {},
  emits: ["hideModal"],
  setup(props, { emit }) {
    const currentPwdErrMsg = "현재 비밀번호가 틀립니다.";

    const state = reactive({
      passwordForm: {
        nowPassword: "",
        password: "",
        passwordConfirmation: "",
      },
      currentPwdErrMsg: "",
      nowPasswordErrorMessage: "",
      passwordErrorMessage: "",
      passwordConfirmationErrorMessage: "",
      isSuccess: computed(() => {
        let isNowPassword = state.passwordForm.nowPassword.trim().length > 0;
        let isPassword = state.passwordForm.password.trim().length > 5;
        let isPasswordConfirmation =
          state.passwordForm.passwordConfirmation.trim().length > 5;
        return isNowPassword && isPassword && isPasswordConfirmation;
      }),
    });

    const actions = {
      updateNowPassword: (data) => {
        state.passwordForm.nowPassword = data;
      },
      updatePassword: (data) => {
        state.passwordForm.password = data;
      },
      updatePasswordConfirmation: (data) => {
        state.passwordForm.passwordConfirmation = data;
      },
      putUserPassword: async () => {
        let password = state.passwordForm.password.trim();
        const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

        let existAlphabet = /[a-zA-Z]/.test(password);
        let existNumber = /\d/.test(password);
        let existSpecialCharacter = specialChars.test(password);
        let validateCount = 0;

        if (existAlphabet) {
          validateCount += 1;
        }
        if (existNumber) {
          validateCount += 1;
        }
        if (existSpecialCharacter) {
          validateCount += 1;
        }

        if (password.length < 6 || validateCount < 2) {
          state.passwordErrorMessage =
            "영문, 숫자, 특수문자를 조합해주세요. (6자리 이상)";
          return;
        } else {
          state.passwordErrorMessage = "";
        }

        if (
          state.passwordForm.password !==
          state.passwordForm.passwordConfirmation
        ) {
          state.passwordConfirmationErrorMessage =
            "새 비밀번호와 일치하지 않습니다.";
          return;
        } else {
          state.passwordConfirmationErrorMessage = "";
        }

        let payload = helper.makeFormData(state.passwordForm, null, "post");

        try {
          await ApiService.postUserChangePassword(payload).then((res) => {
            if (res.data.success) {
              swal.successToast("변경되었습니다.");
              emit("hideModal");
            } else {
              state.nowPasswordErrorMessage = res.data.message;
              state.currentPwdErrMsg = currentPwdErrMsg;
            }
          });
        } catch (e) {
          if (e.response.status === 400) {
            let res = e.response.data;

            if (res.data.nowPassword) {
              state.nowPasswordErrorMessage = res.data.nowPassword[0];
              state.currentPwdErrMsg = res.data.nowPassword[0]
                ? ""
                : currentPwdErrMsg;
            } else if (!res.data.nowPassword) {
              state.currentPwdErrMsg = "";
            }
            if (res.data.password) {
              state.passwordErrorMessage = res.data.password[0];
            } else if (!res.data.password) {
              state.passwordErrorMessage = "";
            }
            if (res.data.passwordConfirmation) {
              state.passwordConfirmationErrorMessage =
                res.data.passwordConfirmation[0];
            } else if (!res.data.passwordConfirmation) {
              state.passwordConfirmationErrorMessage = "";
            }
          }
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
